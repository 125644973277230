<template>
	<h4>You are in home.</h4>
</template>
<script>
	export default {
		data() {
			return {
				vueAppClasses: [],
			}
		},
	}
</script>